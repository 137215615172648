/**
 * External dependencies
 */
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import './styles/_index.scss';

const BtnLink = ({ children, className, href }) => (
  <Link to={href} className={classNames('btn-link', className)}>
    {children}
  </Link>
)

export default BtnLink;
