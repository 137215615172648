/**
 * External dependencies
 */
import React from "react";
import classNames from "classnames";

/**
 * Internal dependencies
 */

import "./styles/_index.scss"

const IconCircle = ({ children, className, color }) => {
  return (
    <div
      className={classNames("icon-circle", className)}
      style={{ background: color }}
    >
      {children}
    </div>
  )
}

export default IconCircle;
