/**
 * External dependencies
 */
import React from "react"

/**
 * Internal dependencies
 */
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import "./styles/_index.scss"

const VideoItem = ({ image, children, isOpen, setIsOpen,  imagetype, ggfxname, altid, imageprocess, imageid, videourl, linkto, propertyDetailsLink, videoTour, propertyLink }) => {
  return (
  <div className="video-item">
    <div className="video-item__player">
      <div className="video-item__player-inner">
        <VideoPlaceholder
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          image={image}
          imagetype={imagetype}
          ggfxname={ggfxname}
          altid={altid}
          imageprocess={imageprocess}
          imageid={imageid}
          videourl={videourl}
          propertyLink={propertyDetailsLink}
        />
      </div>
    </div>
    <div className="video-item__content">{children}</div>
  </div>
)
}
export default VideoItem
